<template>
  <div class="lucky">
    <page-title />
    <div class="lucky-notice">
      <van-notice-bar left-icon="volume-o" :scrollable="false">
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, index) in noticeList" :key="index">
            恭喜{{ item.userName }}获得{{ item.amount / 100 }}元红包
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
    <div class="lucky-box">
      <lucky-grid
        ref="myLucky"
        style="margin: 0 auto"
        width="90vw"
        height="90vw"
        :prizes="prizes"
        :blocks="blocks"
        :buttons="buttons"
        @start="startCallback"
        @end="endCallback"
      />
      <div class="lucky-rank" @click="$router.replace('/home')">
        <img :src="require('@/assets/icons/rule.png')" alt="" />
        <span>回到首页</span>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant'

export default {
  components: {},
  data() {
    return {
      noticeList: [],
      blocks: [
        { padding: '10px', background: '#ffffff' },
        { padding: '10px', background: '#f3f3f3' }
      ],
      prizes: [
        {
          x: 0,
          y: 0,
          index: 0,
          background: '#ffffff',
          fonts: [{ text: '0.3元', top: '40%', fontColor: '#d55625' }]
        },
        {
          x: 1,
          y: 0,
          index: 1,
          background: '#ffffff',
          fonts: [
            {
              text: '谢谢参与',
              fontSize: '16px',
              top: '40%',
              fontColor: '#939393'
            }
          ]
        },
        {
          x: 2,
          y: 0,
          index: 2,
          background: '#ffffff',
          fonts: [{ text: '0.3元', top: '40%', fontColor: '#d55625' }]
        },
        {
          x: 2,
          y: 1,
          index: 3,
          background: '#ffffff',
          fonts: [{ text: '1元', top: '40%', fontColor: '#d55625' }]
        },
        {
          x: 2,
          y: 2,
          index: 4,
          background: '#ffffff',
          fonts: [{ text: '0.5元', top: '40%', fontColor: '#d55625' }]
        },
        {
          x: 1,
          y: 2,
          index: 5,
          background: '#ffffff',
          fonts: [{ text: '2元', top: '40%', fontColor: '#d55625' }]
        },
        {
          x: 0,
          y: 2,
          index: 6,
          background: '#ffffff',
          fonts: [{ text: '0.5元', top: '40%', fontColor: '#d55625' }]
        },
        {
          x: 0,
          y: 1,
          index: 7,
          background: '#ffffff',
          fonts: [{ text: '5元', top: '40%', fontColor: '#d55625' }]
        }
      ],
      defaultStyle: {
        background: '#b8c5f2'
      },
      activeStyle: {
        background: '#feeeac'
      },
      buttons: [
        {
          x: 1,
          y: 1,
          background: '#e3594f',
          fonts: [
            {
              text: '立即\n抽奖',
              fontSize: '24px',
              wordWrap: false,
              top: '20%',
              fontColor: '#ffffff'
            }
          ]
        }
      ]
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    // 点击抽奖按钮会触发star回调
    startCallback() {
      this.$http({
        url: '/xt-exam/lottery/startLottery',
        method: 'get'
      }).then(response => {
        if (response.code == 200) {
          // 调用抽奖组件的play方法开始游戏
          this.$refs.myLucky.play()
          // 模拟调用接口异步抽奖
          setTimeout(() => {
            // 假设后端返回的中奖索引是0
            const index = response.data.indexId
            // 调用stop停止旋转并传递中奖索引
            this.$refs.myLucky.stop(index)
          }, 3000)
        } else {
          Toast.fail(response.msg)
        }
      })
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      console.log(prize)
      if (prize.index == 1) {
        Toast.fail('谢谢参与\n分享可获得更多抽奖次数')
      } else {
        Toast.success(`恭喜您获得\n${prize.fonts[0].text}红包`)
      }
    },
    init() {
      this.$http({
        url: '/xt-exam/lottery/getNewLottery',
        method: 'get'
      }).then(response => {
        if (response.code == 200) {
          console.log(response)
          this.noticeList = (response.data || []).filter(item => item.amount)
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.lucky {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;

  .lucky-notice {
    padding: 3vh 5vw;
    /deep/.van-notice-bar__content.van-ellipsis {
      width: 100%;
    }
  }

  .notice-swipe {
    height: 40px;
    line-height: 40px;
  }
  .lucky-box {
    position: relative;
    flex: 1;
    width: 100vw;
    /deep/.van-button {
      width: 80%;
      margin: 0 auto;
    }
  }

  .lucky-title {
    font-size: 1.79rem;
    font-family: PingFang SC;
    font-weight: 800;
    color: #010e4f;
    margin-top: 10vh;
  }

  .lucky-text {
    font-size: 1.04rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #010e4f;
    margin-top: 2vh;
  }

  .lucky-score-title {
    font-size: 1.04rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #010e4f;
    margin-top: 0.5vh;
  }

  .lucky-score {
    font-size: 3.27rem;
    font-family: PingFang SC;
    font-weight: 800;
    color: #010e4f;
    margin-top: 2vh;
  }

  .button {
    margin-top: 4vh;
    width: 56vw;
    cursor: pointer;
  }

  .lucky-rank {
    font-size: 1.04rem;
    font-family: PingFang SC;
    font-weight: 800;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 3vh;
    img {
      width: 1.29rem;
      height: 1.29rem;
      margin-right: 0.46rem;
    }
  }
}
</style>
